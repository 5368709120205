<template>
  <wx-autocomplete
    v-model="selectedProductionUnits"
    :items="availableProductionUnits"
    item-text="name"
    item-value="id"
    :label="$t('multiPuSelector.label')"
    :rules="[() => this.validateProductionUnits()]"
    clearable
    multiple
    return-object
    @change="emitSelection"
  >
    <template v-slot:prepend-item>
      <v-list-item ripple @click="toggleProductionUnitSelection">
        <v-list-item-content>
          <v-list-item-title>
            <span>{{ $t("common.all") }}</span>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider class="mt-2"></v-divider>
    </template>
    <template v-if="this.isMultipleFactories" v-slot:item="{ item }">
      <span class="wx-typo-sm font-weight-thin">{{ item.factory }}</span>
      <span class="ml-2 wx-typo-sm font-weight-thin">/</span>
      <span class="ml-2 wx-typo-sm">{{ item.name }}</span>
    </template>
    <template v-slot:selection="{ index }">
      <div v-if="allProductionUnitsSelected(index)" class="mt-2">
        <span>{{ $t("common.all") }}</span>
      </div>
      <div v-if="isLessThanMaxChipCount()">
        <v-chip v-if="index < maxNumberOfChips" label small close color="primary" class="my-1" @click:close="removeByIndex(index)">
          <span> {{ selectedProductionUnits[index].name }} </span>
        </v-chip>
        <v-chip v-else-if="index === maxNumberOfChips" label small close color="primary" @click:close="removeByIndex(index)">
          <span>{{ $t("common.numberOfChipsRemaining", [selectedProductionUnits.length - maxNumberOfChips]) }}</span>
        </v-chip>
      </div>
    </template>
  </wx-autocomplete>
</template>

<script>
import WxAutocomplete from "@/components/ui/WxAutocomplete";

export default {
  name: "WxProductionUnitMultiSelector",
  components: {
    WxAutocomplete,
  },
  props: {
    value: {
      type: Array,
      required: true,
    },
    isMultipleFactories: {
      type: Boolean,
      required: true,
    },
    availableProductionUnits: {
      type: Array,
      required: true,
    },
    maxNumberOfChips: {
      type: Number,
      default: () => 6,
    },
    acceptEmptySelection: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      selectedProductionUnits: this.value,
    };
  },
  watch: {
    value() {
      this.selectedProductionUnits = this.value;
    },
    selectedProductionUnits() {
      this.emitSelection();
    },
  },
  computed: {
    icon() {
      if (
        this.selectedProductionUnits &&
        this.selectedProductionUnits.length === this.availableProductionUnits.length
      ) {
        return "mdi-close-box";
      } else {
        return "mdi-checkbox-blank-outline";
      }
    },
  },
  methods: {
    validateProductionUnits() {
      if (!this.selectedProductionUnits || this.selectedProductionUnits.length === 0) {
        if (!this.acceptEmptySelection) {
          return this.$t("multiPuSelector.errors.noProductionUnits");
        }
      }
      return true;
    },
    toggleProductionUnitSelection() {
      this.$nextTick(() => {
        if (this.selectedProductionUnits.length === this.availableProductionUnits.length) {
          this.selectedProductionUnits = [];
        } else {
          this.selectedProductionUnits = [...this.availableProductionUnits];
        }
      });
    },
    allProductionUnitsSelected(index) {
      return index === 0 && this.selectedProductionUnits.length === this.availableProductionUnits.length;
    },
    isLessThanMaxChipCount() {
      return this.selectedProductionUnits.length < this.availableProductionUnits.length;
    },
    removeByIndex(index) {
      let toRemove = this.selectedProductionUnits[index];
      if (toRemove) {
        let newList = [];
        this.selectedProductionUnits.forEach((pu) => {
          if (pu !== toRemove) {
            newList.push(pu);
          }
        });
        this.selectedProductionUnits = newList;
      }
    },
    emitSelection() {
      this.$emit("input", this.selectedProductionUnits);
      this.$emit("updated");
    },
  },
};
</script>

<style lang="scss" scoped>
.pu-as-chips {
  min-height: 40px;
}
</style>
